

.results{
    max-width:90vw;
    width: 400px;
    padding: 24px;
    background-color: var(--box-b-color);
    box-shadow: var(--box-shadow);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* margin: 50px; */
}


.results-grid{
    margin: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 8fr;
    column-gap: 4px;
    row-gap: 8px;
}

.results-grid > div{
    display: flex;
    align-items: center;
}

.results-grid-c1{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    background-color: #f8fcff;
    font-size: 24px;
    transition: 200ms ease-in-out all;
}


.results-grid-c2{
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px;
}

.results-grid-c12{
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px;
    grid-column-start: 0;
    grid-column-end: 2;
}


.results-message{
    color: rgb(218, 0, 0);
    font-size: 16px;
}


@media (hover:hover){
    
.results-grid-c1:hover{
    background-color: rgb(0, 145, 255);
    color: white; 
    user-select: none;
}
}